<template>
  <div class="dispatchingWorkers">
    <van-nav-bar left-text="派工总览"
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true">
      <template #right> </template>
    </van-nav-bar>
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <div class="content">
      <div class="contentTop">
        <div class="contentTopHeader">
          <img src="@/assets/img/jibenxinxitubiao.png"
               alt="" />
          <span>基本信息</span>
        </div>
        <div class="contentTopMain">
          <div class="contentTopMainInfo">
            <div class="contentTopMainInfoIcon">
              <img src="@/assets/img/paichanriqi.png"
                   alt="" />
            </div>
            <div class="contentTopMainInfoWord">单号</div>
            <div class="contentTopMainInfoInput"
                 style="font-size:12px">{{ listno }}</div>
          </div>
          <div class="contentTopMainInfo">
            <div class="contentTopMainInfoIcon">
              <img src="@/assets/img/paichanriqi.png"
                   alt="" />
            </div>
            <div class="contentTopMainInfoWord">排产日期</div>
            <div class="contentTopMainInfoInput">{{ yymmdd }}</div>
          </div>
          <div class="contentTopMainInfo">
            <div class="contentTopMainInfoIcon">
              <img src="@/assets/img/chanxian.png"
                   alt="" />
            </div>
            <div class="contentTopMainInfoWord">产线</div>
            <div class="contentTopMainInfoInput">{{ linename }}</div>
          </div>
          <div class="contentTopMainInfo">
            <div class="contentTopMainInfoIcon">
              <img src="@/assets/img/gongjian.png"
                   alt="" />
            </div>
            <div class="contentTopMainInfoWord">工件</div>
            <div class="contentTopMainInfoInput">{{ partname }}</div>
          </div>
          <div class="contentTopMainInfo">
            <div class="contentTopMainInfoIcon">
              <img src="@/assets/img/shuliang.png"
                   alt="" />
            </div>
            <div class="contentTopMainInfoWord">数量</div>
            <div class="contentTopMainInfoInput">{{ plannum }}</div>
          </div>
        </div>
      </div>
      <div class="contentMain"
           v-if="baseInfoData && dataData && dataData.length > 0">
        <div class="contentMainHeader">
          <div class="contentMainHeaderLeft">
            <img src="@/assets/img/wqdqwdww.png"
                 alt="" />
            <span>派工明细</span>
          </div>
          <div class="contentMainHeaderRight"
               v-if="readyDispatchingWorkers == 2">
            <img src="@/assets/img/pgmxxz.png"
                 alt="" />
            <span class="quanxuan"
                  @click="checkAll(true)">全选</span>
            <span class="shuxian"
                  style="
                height: 0.53333rem;
                width: 2px;
                background: #ccc;
                display: block;
              "></span>
            <span class="fanxuan"
                  @click="checkAll(false)">反选</span>
          </div>
        </div>
        <div class="contentMainTable"
             style="padding-top: 15px; padding-bottom: 15px">
          <div style="width: 100%; overflow: auto">
            <table style="border-collapse: collapse">
              <tr style="background: #e4e9f3; height: 40px">
                <th style="border-right: 1px solid #ccc"></th>
                <th style="border-right: 1px solid #ccc">机台</th>
                <th style="border-right: 1px solid #ccc">工序</th>
                <th style="border-right: 1px solid #ccc">负责人</th>
                <th style="border-right: 1px solid #ccc">协助人员</th>
                <th style="border-right: 1px solid #ccc">派工数量</th>
                <th style="border-right: 1px solid #ccc">实际生产数量</th>
                <th>自动掉落件</th>
              </tr>
              <tr v-for="(value, key) in dataData"
                  :key="key">
                <td style="border-right: 1px solid #ccc">
                  <van-checkbox :disabled="readyDispatchingWorkers == 1"
                                v-model="value.checked"></van-checkbox>
                </td>
                <td style="border-right: 1px solid #ccc">
                  <div class="h5Select"
                       @click="selectJiTaiEvent(value, key, 1)">
                    <input v-model="value.machinename"
                           class="h5SelectInput"
                           readonly
                           type="text" />
                    <van-icon v-if="value.machinename"
                              class="h5SelectIcon"
                              name="cross"
                              @click.stop="cleanUp(value, 'JT')" />
                    <van-icon v-else
                              class="h5SelectIcon"
                              name="arrow-down" />
                  </div>
                </td>
                <td style="border-right: 1px solid #ccc">
                  <div style="width: 100px; text-align: center; font-size: 14px">
                    {{ value.processname }}
                  </div>
                  <!--      2023/08/21 工序只读 -->
                  <!-- <div class="h5Select"
                       @click="selectGongXuEvent(value, key)">
                    <input v-model="value.processname"
                           class="h5SelectInput"
                           readonly
                           type="text" />
                    <van-icon class="h5SelectIcon"
                              name="arrow-down" />
                  </div> -->
                </td>
                <td style="border-right: 1px solid #ccc">
                  <div class="h5Select"
                       @click="selectFuZeRen(value, key)">
                    <input v-model="value.empname"
                           class="h5SelectInput"
                           readonly
                           type="text" />
                    <van-icon v-if="value.empname"
                              class="h5SelectIcon"
                              name="cross"
                              @click.stop="cleanUp(value, 'FZR')" />
                    <van-icon v-else
                              class="h5SelectIcon"
                              name="arrow-down" />
                  </div>
                </td>
                <td style="border-right: 1px solid #ccc">
                  <div style="
                      width: 70px;
                      text-align: center;
                      font-size: 15px;
                      color: blue;
                      text-decoration: underline;
                    "
                       @click="hfwjehed(value, key)">
                    {{ value.xznum ? value.xznum : 0 }}人
                  </div>
                </td>
                <td style="border-right: 1px solid #ccc">
                  <div style="width: 100px; text-align: center; font-size: 14px">
                    {{ value.pgnum }}
                  </div>
                  <!-- <input :disabled="readyDispatchingWorkers == 1"
                         v-model="value.pgnum"
                         type="text"
                         class="h5Input" /> -->
                </td>
                <td style="border-right: 1px solid #ccc">
                  <div style="width: 100px; text-align: center; font-size: 14px">
                    {{ value.bgnum }}
                  </div>
                </td>
                <td>
                  <div style="width: 100px; display: flex; justify-content: center">
                    <van-checkbox v-model="value.isdl"
                                  shape="square"
                                  checked-color="#009944"
                                  :disabled="readyDispatchingWorkers == 1"></van-checkbox>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="detailFooter"
         v-if="baseInfoData &&
      dataData &&
      dataData.length > 0 &&
      readyDispatchingWorkers == 2
      ">
      <div class="detailFooterDiv"
           @click="plpgDj">
        <img class="footerImgIcon"
             src="@/assets/img/piliangpaigong.png"
             alt="批量派工" />
        <span class="footerWord">批量派工</span>
      </div>
      <div class="detailFooterShuXian"></div>
      <div class="detailFooterDiv"
           @click="hkjhfeh">
        <img class="footerImgIcon"
             src="@/assets/img/cafen.png"
             alt="拆分" />
        <span class="footerWord">拆分</span>
      </div>
      <div class="detailFooterShuXian"></div>
      <div class="detailFooterDiv"
           @click="shanChuDj">
        <img class="footerImgIcon"
             src="@/assets/img/lajitong.png"
             alt="删除" />
        <span class="footerWord">删除</span>
      </div>
    </div>
    <!-- 协助人员 -->
    <van-dialog v-model="showAssist"
                class="Assist_report"
                show-cancel-button
                confirm-button-text="确定"
                confirm-button-color="#0782F5"
                :beforeClose="submitAutoReport">
      <template #title>
        <div class="title">协助人员</div>
      </template>
      <div class="contentss"
           v-if="selectXzryObj">
        <div class="contentCell">
          <div class="AssistCell">
            <div class="AssistCellLeft">派工日期</div>
            <div class="AssistCellRight">{{ yymmdd }}</div>
          </div>
          <div class="AssistCell">
            <div class="AssistCellLeft">机台</div>
            <div class="AssistCellRight">{{ linename }}</div>
          </div>
          <div class="AssistCell">
            <div class="AssistCellLeft">工序</div>
            <div class="AssistCellRight">{{ selectXzryObj.processname }}</div>
          </div>
        </div>
        <div class="contentPersonnel">
          <div class="contentPersonnelTop">
            <div class="contentPersonnelTopLeft">
              <img class="contentPersonnelTopLeftIcon"
                   src="@/assets/img/xiezhurenyuan.png"
                   alt="" />
              <span class="contentPersonnelTopLeftSpan">协助人员</span>
            </div>
            <div class="contentPersonnelTopRight"
                 v-if="readyDispatchingWorkers == 2"
                 @click="addXzRy">
              <img class="contentPersonnelTopRightIcon"
                   src="@/assets/img/xiezhurenyuantianjia.png"
                   alt="" />
              <span>添加</span>
            </div>
          </div>
          <div class="contentPersonnelMain">
            <div style="margin-right: 20px; margin-bottom: 10px"
                 class="contentPersonnelMainCell"
                 v-for="(value, key) in xieZryData"
                 :key="key">
              <img class="contentPersonnelMainIcon"
                   src="@/assets/img/rewded.png"
                   alt="" />
              <span>{{ value.empname }}</span>
              <van-icon v-if="readyDispatchingWorkers == 2"
                        @click="shanChuXzry(value, key)"
                        color="#666666"
                        name="close" />
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
    <!-- 拆分派工单 -->
    <van-dialog v-model="showSplitDispatchOrder"
                class="SplitDispatchOrder_report"
                show-cancel-button
                confirm-button-text="完成拆分"
                confirm-button-color="#0782F5"
                :beforeClose="submitSplitDispatchOrder">
      <template #title>
        <div class="title">拆分派工单</div>
        <div class="des">请谨慎操作</div>
      </template>
      <div class="contentss">
        <div class="contentCell">
          <div class="AssistCell">
            <div class="AssistCellLeft">派工日期</div>
            <div class="AssistCellRight">
              <div class="showPopup"
                   @click="handshowDtPicker"
                   style="
                  color: #333;
                  width: 186px;
                  display: flex;
                  align-items: center;
                  height: 33px;
                  border: 1px solid #ccc;
                  border-radius: 5px;
                  padding: 5px 13px;
                  justify-content: space-between;
                ">
                <svg-icon style="margin-right: 8px"
                          icon-class="interview_rili"></svg-icon>
                {{ yymmdd2 }}
                <span>
                  <svg-icon icon-class="dropdown"></svg-icon>
                </span>
              </div>
            </div>
          </div>
          <div class="AssistCell">
            <div class="AssistCellLeft">机台</div>
            <div class="AssistCellRight">
              <div class="h5Select"
                   style="width: 186px; height: 33px"
                   @click="selectJiTaiEvent(null, null, 2)">
                <input v-model="cfPgdJt"
                       class="h5SelectInput"
                       readonly
                       type="text" />
                <van-icon class="h5SelectIcon"
                          name="arrow-down" />
              </div>
            </div>
          </div>
          <div class="AssistCell">
            <div class="AssistCellLeft">数量</div>
            <div class="AssistCellRight">
              <input v-model="cfPgdSl"
                     style="width: 186px; height: 33px"
                     type="text"
                     class="h5Input" />
            </div>
          </div>
        </div>
        <div class="contentPersonnel">
          <div class="contentPersonnelTop">
            <div class="contentPersonnelTopLeft">
              <img class="contentPersonnelTopLeftIcon"
                   src="@/assets/img/xiezhurenyuan.png"
                   alt="" />
              <span class="contentPersonnelTopLeftSpan">负责人<strong v-if="daiBanRy">（{{ daiBanRy }}）</strong></span>
            </div>
            <div class="contentPersonnelTopRight"
                 @click="showCaFenFuZrPop">
              <img class="contentPersonnelTopRightIcon"
                   src="@/assets/img/qdwqdqqwdwqd.png"
                   alt="" />
              <span>编辑</span>
            </div>
          </div>
        </div>
        <div class="contentPersonnel"
             style="margin-top: 0.46667rem">
          <div class="contentPersonnelTop">
            <div class="contentPersonnelTopLeft">
              <img class="contentPersonnelTopLeftIcon"
                   src="@/assets/img/xiezhurenyuan.png"
                   alt="" />
              <span class="contentPersonnelTopLeftSpan">协助人员</span>
            </div>
            <div v-if="readyDispatchingWorkers == 2"
                 class="contentPersonnelTopRight"
                 @click="showCaFenXzRPop">
              <img class="contentPersonnelTopRightIcon"
                   src="@/assets/img/xiezhurenyuantianjia.png"
                   alt="" />
              <span>添加</span>
            </div>
          </div>
          <div class="contentPersonnelMain">
            <div style="margin-right: 20px; margin-bottom: 10px"
                 class="contentPersonnelMainCell"
                 v-for="(value, key) in cfXzRdata"
                 :key="key">
              <img class="contentPersonnelMainIcon"
                   src="@/assets/img/rewded.png"
                   alt="" />
              <span>{{ value.empname }}</span>
              <van-icon v-if="readyDispatchingWorkers == 2"
                        @click="shanChuXzry2(value, key)"
                        color="#666666"
                        name="close" />
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
    <!-- 选择机台 -->
    <van-popup v-model="showJiTaiPicker"
               position="bottom"
               round>
      <van-picker show-toolbar
                  :columns="machineList"
                  @confirm="onJiTaiPickerConfirm"
                  @cancel="showJiTaiPicker = false">
        <template #title>
          <van-search shape="round"
                      v-model="searchJiTai"
                      placeholder="搜索关键词"
                      @input="searchJTList" />
        </template>
      </van-picker>
    </van-popup>
    <!-- 选择工序 -->
    <van-popup v-model="showGongXuPicker"
               position="bottom"
               round>
      <van-picker show-toolbar
                  :columns="processList"
                  @confirm="onGongXuPickerConfirm"
                  @cancel="showGongXuPicker = false">
      </van-picker>
    </van-popup>
    <PopTree ref="popTreeNode"
             :whichTree="'employee'"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent">
    </PopTree>
    <PopTree ref="popTreeNode2"
             :whichTree="'employee'"
             :multiseltree="true"
             @treeSelectValEvent="treeSelectValEvent2">
    </PopTree>
    <PopTree ref="showCaFenFuZrPopREF"
             :whichTree="'employee'"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent3"></PopTree>
    <PopTree ref="showCaFenXzRPopREF"
             :whichTree="'employee'"
             :multiseltree="true"
             @treeSelectValEvent="treeSelectValEvent4"></PopTree>
    <!--  -->
    <!-- 选择日期 -->
    <van-calendar v-model="showDtPicker"
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onDtConfirm" />

    <!-- 提示信息弹窗 -->
    <van-dialog v-model="showHintDia"
                class="hint_dialog"
                :show-confirm-button="false">
      <template #title>
        <div style="height:60px"></div>
      </template>
      <div class="content">
        <img :src="require(isSuccess ? '@/assets/img/job_booking11.png' : '@/assets/img/job_booking10.png')"
             alt />
        <div class="hint_msg">{{ hintMessage }}</div>
        <van-button type="info"
                    size="small"
                    round
                    @click="showHintDia = false">好的</van-button>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { parseTime } from "@/utils";
import {
  getEmpPgDataList,
  getJtList,
  getProcessList,
  getPgXzEmpList,
  savePgXzEmpList,
  postPgDetailData,
  batchDeletePgDetailData,
  calSplitPg,
} from "@api/paigong.js";
import PopTree from "@components/PopTree/index.vue";
import { Toast } from "vant";
export default {
  name: "dispatchingWorkers",
  components: {
    PopTree,
  },
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      yymmdd2: parseTime(new Date(), "{y}-{m}-{d}"),
      showDtPicker: false,
      checked: true,
      showAssist: false,
      showSplitDispatchOrder: false,
      userInfo,
      autoid: this.$route.query.autoid || null,
      bd: this.$route.query.bd || "",
      ed: this.$route.query.ed || "",
      yymmdd: this.$route.query.yymmdd || "",
      linename: this.$route.query.linename || "",
      machinename: this.$route.query.machinename || "",
      partname: this.$route.query.partname || "",
      plannum: this.$route.query.plannum || null,
      listno: this.$route.query.listno || "",
      baseInfoData: null,
      dataData: null,
      showJiTaiPicker: false,
      showJiTaiSelectIndex: 0,
      bsMachineList: [],
      machineList: [],
      showGongXuPicker: false,
      showGongXuIndex: 0,
      bsProcessList: [],
      processList: [],
      fuZeRenIndex: 0,
      selectXzryObj: null,
      selectXzryKey: 0,
      xieZryData: [],
      successpath: require("@/assets/img/chenggong.png"),
      cfPgdJt: "",
      cfPgdJtId: null,
      cfPgdSl: "",
      showJiTaiSelectType: 1,
      daiBanRy: "", // 负责人
      daiBanRyId: null,
      daiBanRyCode: null,
      cfXzRdata: [],
      readyDispatchingWorkers: 2,
      showHintDia: false,
      isSuccess: true,
      hintMessage: '',
      searchJiTai: '', // 机台下拉搜索值
    };
  },
  watch: {
    showJiTaiPicker (n) {
      if (n)      {
        this.getSelectData();
      } else      {
        this.searchJiTai = ''
      }
    }
  },
  created () {
    this.readyDispatchingWorkers = sessionStorage.getItem(
      "readyDispatchingWorkers"
    );
    this.getSelectData();
    this.getData();
  },
  methods: {
    // 表格数据清除
    cleanUp (val, type) {
      if (type == 'JT')      {
        this.dataData.forEach(el => {
          if (el.autoid == val.autoid)          {
            el.machineid = ''
            el.machinename = ''
          }
        });
      } else if (type == 'FZR')      {
        this.dataData.forEach(el => {
          if (el.autoid == val.autoid)          {
            el.empcode = ''
            el.empid = ''
            el.empname = ''
          }
        });
      }
    },
    // 机台下拉搜索
    searchJTList () {
      if (this.searchJiTai)      {
        this.machineList = this.machineList.filter(it => { return it.indexOf(this.searchJiTai) >= 0 })
      } else      {
        this.getSelectData();
      }
    },
    getSelectData () {
      getJtList({
        username: this.userInfo.username,
      }).then((res) => {
        this.bsMachineList = res.data;
        this.machineList = []
        if (res.data.length > 0)        {
          for (let i = 0; i < res.data.length; i++)          {
            this.machineList.push(res.data[i].machinename);
          }
        }
      });
      getProcessList({
        username: this.userInfo.username,
      }).then((res) => {
        this.bsProcessList = res.data;
        this.processList = []
        if (res.data.length > 0)        {
          for (let i = 0; i < res.data.length; i++)          {
            this.processList.push(res.data[i].processname);
          }
        }
      });
    },
    getData () {
      getEmpPgDataList({
        autoid: this.$route.query.autoid,
        yymmdd: this.$route.query.yymmdd,
        username: this.userInfo.username,
      }).then((res) => {
        if (res.data)        {
          res.data.forEach((item) => {
            item.checked = false;
          });
          let result = JSON.parse(JSON.stringify(res.data))
          this.dataData = res.data
          this.initDataData = result
        }
        if (res.baseinfo)        {
          this.baseInfoData = res.data
        }
      });
    },

    // 选择机台
    selectJiTaiEvent (value, key, type) {
      if (this.readyDispatchingWorkers == 1)      {
        return;
      }
      this.showJiTaiSelectType = type;
      if (type == 1)      {
        this.showJiTaiSelectIndex = key;
      } else      {
        this.cfPgdJt = "";
        this.cfPgdJtId = null;
      }
      this.showJiTaiPicker = true;
    },
    onJiTaiPickerConfirm (value) {
      let arr = this.bsMachineList.filter((item) => {
        return item.machinename == value;
      });
      if (this.showJiTaiSelectType == 1)      {
        this.dataData[this.showJiTaiSelectIndex].machinename = value;
        this.dataData[this.showJiTaiSelectIndex].machineid = arr[0].machineid;
      } else      {
        this.cfPgdJt = value;
        this.cfPgdJtId = arr[0].machineid;
      }

      this.showJiTaiPicker = false;
    },
    // 选择工序
    selectGongXuEvent (value, key) {
      if (this.readyDispatchingWorkers == 1)      {
        return;
      }
      this.showGongXuIndex = key;
      this.showGongXuPicker = true;
    },
    onGongXuPickerConfirm (value) {
      let arr = this.bsProcessList.filter((item) => {
        return item.processname == value;
      });
      this.dataData[this.showGongXuIndex].processname = value;
      this.dataData[this.showGongXuIndex].processid = arr[0].processid;
      this.showGongXuPicker = false;
    },
    // 选择负责人
    selectFuZeRen (value, key) {
      if (this.readyDispatchingWorkers == 1)      {
        return;
      }
      this.fuZeRenIndex = key;
      this.$refs.popTreeNode.module = "813";
      this.$refs.popTreeNode.treename = "glsemptree";
      this.$refs.popTreeNode.editstate = 2;
      this.$refs.popTreeNode.showEmpTreePop("选择负责人");
    },
    treeSelectValEvent (data) {
      this.dataData[this.fuZeRenIndex].empname = data.empname;
      this.dataData[this.fuZeRenIndex].empid = data.empid;
      this.dataData[this.fuZeRenIndex].empcode = data.empcode;
    },
    checkAll (val) {
      if (val)      {
        // 全选
        this.dataData.forEach((ele) => {
          ele.checked = true;
        });
      } else      {
        // 反选
        this.dataData.forEach((ele) => {
          ele.checked = !ele.checked;
        });
      }
      this.$forceUpdate();
    },
    onClickLeft () {
      this.$router.push(
        {
          path: "/dispatchingWorkers",
          query: {
            bd: this.$route.query.bd || "",
            ed: this.$route.query.ed || ""
          }
        });
    },
    // 协助人员
    hfwjehed (value, key) {
      getPgXzEmpList({
        autoid: value.autoid,
        username: this.userInfo.username,
      }).then((res) => {
        this.xieZryData = res.data;
        this.selectXzryObj = value;
        this.selectXzryKey = key;
        this.showAssist = true;
        console.log(res, "dwdwwd");
      });
    },
    // 添加协助人员
    addXzRy () {
      this.$refs.popTreeNode2.module = "813";
      this.$refs.popTreeNode2.treename = "glsemptree";
      this.$refs.popTreeNode2.editstate = 2;
      this.$refs.popTreeNode2.showEmpTreePop("选择协助人员");
    },
    shanChuXzry (value, key) {
      this.xieZryData.splice(key, 1);
    },

    // 保存
    submitAutoReport (action, done) {
      if (this.readyDispatchingWorkers == 1)      {
        done();
        return;
      }
      if (action == "confirm")      {
        // if (this.xieZryData.length == 0)        {
        //   Toast("请添加协助人员！");
        //   done(false);
        //   return;
        // }
        const arr = this.xieZryData.map((item) => {
          return item.empid;
        });
        savePgXzEmpList({
          autoid: this.selectXzryObj.autoid,
          username: this.userInfo.username,
          empidlist: arr.join(","),
        }).then((res) => {
          this.showHintDia = true
          if (res.data[0].info == '')          {
            this.isSuccess = true
            this.hintMessage = '保存成功！'
            this.dataData[this.selectXzryKey].xznum = arr.length;
            // this.getData()
            done();
          } else          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
            done(false);
          }
        });
      } else      {
        done();
      }
    },

    treeSelectValEvent2 (data) {
      for (let i = 0; i < data.length; i++)      {
        let obj = {
          autoid: null,
          empid: null,
          empcode: "",
          empname: "",
        };
        obj.autoid = null;
        obj.empid = data[i].empid;
        obj.empcode = data[i].empcode;
        obj.empname = data[i].empname;
        this.xieZryData.push(obj);
      }
      let map = new Map();
      for (let item of this.xieZryData)      {
        map.set(item.empid, item);
      }
      this.xieZryData = [...map.values()];
      console.log(this.xieZryData, "treeSelectValEvent2treeSelectValEvent2");
    },
    // 批量派工
    plpgDj () {
      const arr = this.dataData.filter((item) => {
        if (item.checked)        {
          return item;
        }
      });
      if (arr && arr.length > 0 && arr[0])      {
        postPgDetailData({
          pg_autoid: this.autoid,
          username: this.userInfo.username,
          data: arr,
        }).then((res) => {
          this.showHintDia = true
          if (res.data[0].info == '')          {
            this.isSuccess = true
            this.hintMessage = '派工成功！'
            this.getData()
          } else          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
          }
        });
      } else      {
        Toast("请选择派工数据");
      }
    },
    // 删除
    shanChuDj () {
      const arr = this.dataData.filter((item) => {
        if (item.checked)        {
          return item;
        }
      });
      const arr2 = arr.map((item) => {
        return item.autoid;
      });
      console.log(arr2);
      if (arr && arr.length > 0 && arr[0])      {
        batchDeletePgDetailData({
          autoidlist: arr2.join(","),
          username: this.userInfo.username,
        }).then((res) => {
          this.showHintDia = true
          if (res.data[0].info == '')          {
            this.isSuccess = true
            this.hintMessage = '删除成功！'
            this.getData()
          } else          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
          }
        });
      } else      {
        Toast("请选择派工数据");
      }
    },
    hkjhfeh () {
      this.cfXzRdata = [];
      this.daiBanRy = "";
      this.daiBanRyId = null;
      this.daiBanRyCode = null;
      this.cfPgdJt = "";
      this.cfPgdJtId = null;
      this.cfPgdSl = "";
      const arr3 = this.dataData.filter((item) => {
        if (item.checked)        {
          return item;
        }
      });

      if (arr3 && arr3.length != 1)      {
        Toast("请选择1条派工数据");
        return;
      } else      {
        let changeAry = []
        this.dataData.forEach(ele => {
          this.initDataData.forEach(it => {
            if (ele.autoid == it.autoid)            {
              const gai = JSON.stringify(ele)
              const yuan = JSON.stringify(it)
              if (gai != yuan)              {
                changeAry.push(ele)
              }
            }
          });
        });
        // console.log(changeAry, 'changeArychangeArychangeAry');
        if (changeAry.length > 0)        {
          postPgDetailData({
            pg_autoid: this.autoid,
            username: this.userInfo.username,
            data: changeAry,
          }).then((res) => {
            if (res.data[0].info == '')            {
              this.showSplitDispatchOrder = true;
            } else            {
              this.isSuccess = false
              this.hintMessage = res.data[0].info
            }
          });
        }

      }
    },
    handshowDtPicker () {
      this.showDtPicker = true;
    },
    // 下拉框选择日期
    onDtConfirm (v) {
      this.yymmdd2 = parseTime(v, "{y}-{m}-{d}");
      this.showDtPicker = false;
    },
    showCaFenFuZrPop () {
      this.$refs.showCaFenFuZrPopREF.module = "813";
      this.$refs.showCaFenFuZrPopREF.treename = "glsemptree";
      this.$refs.showCaFenFuZrPopREF.editstate = 2;
      this.$refs.showCaFenFuZrPopREF.showEmpTreePop("选择负责人");
    },
    treeSelectValEvent3 (data) {
      this.daiBanRy = data.empname;
      this.daiBanRyId = data.empid;
      this.daiBanRyCode = data.empcode;
    },
    showCaFenXzRPop () {
      this.$refs.showCaFenXzRPopREF.module = "813";
      this.$refs.showCaFenXzRPopREF.treename = "glsdbtree";
      this.$refs.showCaFenXzRPopREF.editstate = 2;
      this.$refs.showCaFenXzRPopREF.showEmpTreePop("选择协助人");
      //
    },
    treeSelectValEvent4 (data) {
      for (let i = 0; i < data.length; i++)      {
        let obj = {
          autoid: null,
          empid: null,
          empcode: "",
          empname: "",
        };
        obj.autoid = null;
        obj.empid = data[i].empid;
        obj.empcode = data[i].empcode;
        obj.empname = data[i].empname;
        this.cfXzRdata.push(obj);
      }
      let map = new Map();
      for (let item of this.cfXzRdata)      {
        map.set(item.empid, item);
      }
      this.cfXzRdata = [...map.values()];
      console.log(this.cfXzRdata, "treeSelectValEvent2treeSelectValEvent2");
    },
    shanChuXzry2 (value, key) {
      this.cfXzRdata.splice(key, 1);
    },
    submitSplitDispatchOrder (action, done) {
      if (action == "confirm")      {
        const arr3 = this.dataData.filter((item) => {
          if (item.checked)          {
            return item;
          }
        });

        if (arr3 && arr3.length == 1)        {
          // if (!this.cfPgdJtId)          {
          //   Toast("请选择机台！");
          //   done(false);
          //   return;
          // }
          if (!this.cfPgdSl)          {
            Toast("请输入数量！");
            done(false);
            return;
          }
          if (!this.daiBanRyId)          {
            Toast("请选择负责人！");
            done(false);
            return;
          }
          // 2023/08/21 协助人员不必填。
          // if (this.cfXzRdata.length == 0)          {
          //   Toast("请添加协助人员！");
          //   done(false);
          //   return;
          // }
          const arr = this.cfXzRdata.map((item) => {
            return item.empid;
          });
          calSplitPg({
            autoid: arr3[0].autoid,
            yymmdd: this.yymmdd2,
            machineid: this.cfPgdJtId,
            pgnum: this.cfPgdSl,
            empid: this.daiBanRyId,
            username: this.userInfo.username,
            xz_empidlist: arr.join(","),
          }).then((res) => {
            this.showHintDia = true
            if (res.data[0].info == '')            {
              this.isSuccess = true
              this.hintMessage = '拆分成功！'
              this.getData()
              done();
            } else            {
              this.isSuccess = false
              this.hintMessage = res.data[0].info
              done(false);
            }          }).catch((err) => {
            done(false);
          });
        } else        {
          done();
          Toast("请选择一条派工数据");
        }
      } else      {
        done();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.hint_dialog {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/job_booking09.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;

    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .desc {
      font-size: 28px;
    }
  }

  .content {
    text-align: center;

    img {
      width: 160px;
      height: 160px;
    }

    .hint_msg {
      margin-top: 20px;
      font-size: 32px;
      font-weight: bold;
    }

    .van-button {
      margin: 40px 0;
      width: 240px;
      font-size: 28px;
    }
  }
}

.dispatchingWorkers {
  height: 100vh;

  background-image: linear-gradient(to bottom, #bcdfff, #eaedef);
  overflow: hidden;
  background-color: #eaedef;
  background-repeat: no-repeat;
  background-size: 100% 300px;
  background-position-y: 1.22667rem;
  overflow: auto;

  .van-nav-bar {
    background: #bcdfff;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #333;
        font-size: 0.5rem;
      }

      .van-nav-bar__text {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
    }

    .showPopup {
      color: #333;

      span {
        color: #999;
        font-size: 24px;
        margin-left: 20px;

        .svg-icon {
          margin: 0;
        }
      }

      .svg-icon {
        // margin: 0 5px;
      }
    }
  }

  .content {
    padding-bottom: 150px;

    .contentTop {
      width: 100%;
      margin: 28px 0;
      padding: 0 26px;

      .contentTopHeader {
        display: flex;
        align-items: center;
        width: 100%;
        background: linear-gradient(90deg, #007aec 0%, #00b4ff 100%);
        padding: 20px 30px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        img {
          width: 40px;
          height: 40px;
          display: block;
        }

        span {
          font-size: 32px;
          font-weight: bold;
          margin-left: 10px;
          color: #fff;
        }
      }

      .contentTopMain {
        width: 100%;
        background: #fff;
        padding: 10px 30px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      .contentTopMainInfo {
        display: flex;
        height: 36px;
        align-items: center;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;

        .contentTopMainInfoIcon {
          width: 30px;
          height: 30px;

          img {
            width: 30px;
            height: 30px;
            display: block;
          }
        }

        .contentTopMainInfoWord {
          font-size: 30px;
          line-height: 1;
          color: #8d8d8d;
          margin-left: 16px;
          width: 2.5rem;
        }

        .contentTopMainInfoInput {
          font-size: 30px;
          font-weight: bold;
          color: #000;
          line-height: 1;
          margin-left: 16px;
        }
      }
    }

    .contentMain {
      width: 100%;
      padding: 0 26px;

      .contentMainHeaderLeft {
        display: flex;
        align-items: center;
        flex: 4;

        img {
          width: 40px;
          height: 40px;
          display: block;
        }

        span {
          font-size: 32px;
          font-weight: bold;
          margin-left: 10px;
          color: #000;
        }
      }

      .contentMainHeader {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 30px;
        border-radius: 20px 20px 0 0;
        background: #ffffff;
      }
    }

    .contentMainHeaderRight {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 3;

      img {
        width: 40px;
        height: 40px;
        display: block;
      }

      span {
        font-size: 32px;
        line-height: 1;
        margin-left: 20px;
        color: #000;
      }
    }
  }
}

.h5Select {
  width: 170px;
  padding: 5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  background: #fff;

  .h5SelectInput {
    border: 0;
    flex: 1;
    max-width: calc(100% - 48px);
    outline: 0 !important;
  }

  .h5SelectIcon {
    width: 48px;
    font-size: 32px;
  }
}

.h5Input {
  width: 180px;
  border: 1px solid #d2d2d2;
  outline: 0 !important;
  border-radius: 10px;
  padding-left: 8px;
}

.contentMainTable {
  width: 100%;
  overflow: hidden;
  padding: 0 0.34667rem;
  background: #fff;

  table {
    th {
      font-size: 26px;
      color: #000000;
    }

    td {
      padding: 15px 20px;

      input {
        font-size: 28px;
      }
    }
  }
}

table tr:nth-child(odd) {
  background-color: #f1f5fc;
}

table tr:nth-child(even) {
  background-color: #ffffff;
}

.detailFooter {
  width: 100%;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  height: 100px;
  align-items: center;
  background: #fff;
  box-shadow: 4px 3px 15px 0px rgba(175, 175, 175, 0.21);
  padding: 0 0.69334rem;

  .footerImgIcon {
    width: 36px;
    height: 36px;
    display: block;
  }

  .footerWord {
    font-size: 36px;
    margin-left: 12px;
    line-height: 1;
  }

  .detailFooterDiv {
    display: flex;
    align-items: center;
  }

  .detailFooterShuXian {
    height: 36px;
    width: 1px;
    background: #c2c2c2;
    margin: 0 42px;
  }
}

.Assist_report {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/xiezhurenyuandb.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;
    height: 2.3rem;

    .title {
      font-size: 52px;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 20px 40px;
      text-align: left;
    }

    .desc {
      font-size: 28px;
    }
  }
}

.contentCell {
  width: 100%;
  padding: 0;

  .AssistCell {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    align-items: center;

    .AssistCellLeft {
      width: 2.3rem;
      line-height: 1;
      text-align: right;
      color: #7f7f7f;
    }

    .AssistCellRight {
      flex: 1;
      line-height: 1;
      padding-left: 42px;
    }
  }
}

.contentPersonnel {
  padding: 0 0.53333rem;
}

.contentPersonnelTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding-top: 35px;

  .contentPersonnelTopLeft {
    display: flex;
    align-items: center;

    .contentPersonnelTopLeftIcon {
      width: 34px;
      height: 35px;
    }

    .contentPersonnelTopLeftSpan {
      font-size: 32px;
      line-height: 1;
      margin-left: 10px;
    }
  }

  .contentPersonnelTopRight {
    display: flex;
    align-items: center;

    .contentPersonnelTopRightIcon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }

    span {
      font-size: 32px;
      line-height: 1;
    }
  }
}

.contentPersonnelMain {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: auto;
  min-height: 80px;
}

.contentPersonnelMainCell {
  display: flex;
  align-items: center;

  .contentPersonnelMainIcon {
    width: 32px;
    height: 34px;
  }

  span {
    line-height: 1;
    font-size: 32px;
    margin: 0 14px;
  }
}

.SplitDispatchOrder_report {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/weffwfeffefe.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;
    height: 2.3rem;

    .title {
      font-size: 52px;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 20px 40px;
      text-align: left;
      padding-bottom: 0;
      padding-top: 10px;
    }

    .des {
      font-size: 28px;
      text-align: left;
      padding-left: 40px;
    }
  }
}

.showPopup {
  color: #333;

  span {
    color: #999;
    font-size: 24px;
    margin-left: 10px;

    .svg-icon {
      margin: 0;
    }
  }

  .svg-icon {
    // margin: 0 5px;
  }
}
</style>